import React from 'react'
import starIcon from '../../assets/images/star-icon.png'

const PanaceaDeployments = () => {

    const sites = [
        {SiteName : "Makkah Regional Lab, Makkah, KSA"  , Desc: "",  DeployDate: {Month: "December" , year: "2017" }},
        {SiteName : "Prince Muhammad Bin Abdul Aziz Hospital, Riyadh, KSA"  , Desc: "",  DeployDate: {Month: "April" , year: "2018" }},
        {SiteName : "Saudi German Hospital Riyadh "  , Desc: "",  DeployDate: {Month: "April" , year: "2020" }},
        {SiteName : "Saudi German Hospital Aseer"  , Desc: "",  DeployDate: {Month: "April" , year: "2020" }},
        {SiteName : "Saudi German Hospital Madinah "  , Desc: "",  DeployDate: {Month: "October" , year: "2020" }},
        {SiteName : "Hayat National Hospital, Aseer, KSA "  , Desc: "",  DeployDate: {Month: "November" , year: "2020" }},
        {SiteName : "Saudi German Hospital Jeddah "  , Desc: "",  DeployDate: {Month: "January" , year: "2021" }},
        {SiteName : "Saudi German Hospital Hail "  , Desc: "",  DeployDate: {Month: "January" , year: "2021" }},
        {SiteName : "Saudi German Hospital Dammam"  , Desc: "",  DeployDate: {Month: "January" , year: "2021" }},
        {SiteName : "RBC Muzaffarabad AIMS Hospital, AJK, Pakistan"  , Desc: "",  DeployDate: {Month: "October" , year: "2017" }},
        {SiteName : "RBC Peshawar Hayatabad Medical Complex, Peshawar, Pakistan"  , Desc: "",  DeployDate: {Month: "October" , year: "2016" }},
        {SiteName : "RBC Gilgit, Gilgit Baltistan"  , Desc: "",  DeployDate: {Month: "June" , year: "2016" }},
        {SiteName : "RBC Swat "  , Desc: "",  DeployDate: {Month: "December" , year: "2020" }},
        {SiteName : "Recep Tayyip Erdoğan Hospital Blood Bank, Muzaffargarh, Pakistan"  , Desc: "",  DeployDate: {Month: "November" , year: "2013" }},
        {SiteName : "The Indus Hospital Al Ghazi Campus Blood Bank, Bhong, Sadiqabad, Pakistan"  , Desc: "",  DeployDate: {Month: "January" , year: "2016" }},
        {SiteName : "Shahbaz Sharif Hospital Blood Bank, Bedian Lahore, Pakistan"  , Desc: "",  DeployDate: {Month: "February" , year: "2016" }},
        {SiteName : "RBC Bahawalpur Victoria Hospital  Bahawalpur, Pakistan"  , Desc: "",  DeployDate: {Month: "May" , year: "2016" }},
        {SiteName : "RBC Multan, Pakistan"  , Desc: "",  DeployDate: {Month: "May" , year: "2016" }},
        {SiteName : "Chugtai Lab, Lahore, Pakistan"  , Desc: "",  DeployDate: {Month: "March" , year: "2017" }},
        {SiteName : "Nishtar Hospital Blood Bank, Multan, Pakistan"  , Desc: "",  DeployDate: {Month: "October" , year: "2017" }},
        {SiteName : "Manawan Hospital Blood Bank, Lahore, Pakistan"  , Desc: "",  DeployDate: {Month: "November" , year: "2017" }},
        {SiteName : "THQ Sabzazar Hospital Blood Bank, Lahore, Pakistan"  , Desc: "",  DeployDate: {Month: "November" , year: "2017" }},
        {SiteName : "THQ Hospital Blood Bank, Riwand, Lahore, Pakistan"  , Desc: "",  DeployDate: {Month: "January" , year: "2018" }},
        {SiteName : "THQ Hospital Blood Bank, Kahna, Lahore, Pakistan"  , Desc: "",  DeployDate: {Month: "May" , year: "2018"}},
        {SiteName : "Labour Room Nishtar, Multan, Pakistan"  , Desc: "",  DeployDate: {Month: "November" , year: "2019" }},
        {SiteName : "ER Bahawal Victoria, Bahawalpur, Pakistan"  , Desc: "",  DeployDate: {Month: "November" , year: "2019" }},
        {SiteName : "Chugtai Lab, Jail Road Lahore, Pakistan "  , Desc: "",  DeployDate: {Month: "December" , year: "2019" }},
        {SiteName : "RBC Islamabad, Pakistan "  , Desc: "",  DeployDate: {Month: "February" , year: "2022" }},
        {SiteName : "RBC Abbottabad, Pakistan  "  , Desc: "",  DeployDate: {Month: "December" , year: "2021" }},
        {SiteName : "Dr. Ziauddin Hospital, North Nazimabad, Karachi, Pakistan"  , Desc: "",  DeployDate: {Month: "November" , year: "2012" }},
        {SiteName : "The Indus Hospital Blood Center, Karachi, Pakistan"  , Desc: "",  DeployDate: {Month: "October" , year: "2013" }},
        {SiteName : "National Institute of Blood Diseases, Karachi, Pakistan"  , Desc: "",  DeployDate: {Month: "August" , year: "2015" }},
        {SiteName : "Civil Hospital Blood Bank, Badin, Pakistan"  , Desc: "",  DeployDate: {Month: "June" , year: "2016" }},
        {SiteName : "Dr. Ziauddin Hospital Clifton, Karachi, Pakistan"  , Desc: "",  DeployDate: {Month: "August" , year: "2017" }},
        {SiteName : "RBC Sukkur GMC, Sukkur, Pakistan"  , Desc: "",  DeployDate: {Month: "June" , year: "2018" }},
        {SiteName : "RBC Jamshoro LUMS, Jamshoro, Pakistan"  , Desc: "",  DeployDate : {Month: "September" , year: "2018" }},
        {SiteName : "TIH (LUMHS), Hyderabad, Pakistan"  , Desc: "",  DeployDate: {Month: "October" , year: "2018" }},
        {SiteName : "Jinnah Sindh Medical University, Karachi, Pakistan "  , Desc: "",  DeployDate: {Month: "April" , year: "2019" }},
        {SiteName : "TIH (Civil), Thatta, Pakistan"  , Desc: "",  DeployDate: {Month: "June" , year: "2019" }},
        {SiteName : "Al-Khidmat Hospital, Karachi, Pakistan"  , Desc: "",  DeployDate: {Month: "September" , year: "2019" }},
        {SiteName : "RBC Benazirabad, Nawabshah, Pakistan "  , Desc: "",  DeployDate: {Month: "October" , year: "2019" }},
        {SiteName : "Civil Hospital Larkana, Pakistan"  , Desc: "",  DeployDate: {Month: "December" , year: "2019" }},
        {SiteName : "Civil Hospital Khairpur, Pakistan"  , Desc: "",  DeployDate: {Month: "December" , year: "2019" }},
        {SiteName : "RBC Karachi, Pakistan"  , Desc: "",  DeployDate: {Month: "December" , year: "2019" }},
        {SiteName : "Civil Hospital Jacobabad, Sindh, Pakistan"  , Desc: "",  DeployDate: {Month: "January" , year: "2020" }},
        {SiteName : "Dr. Ruth km Pfau Civil Hospital Karachi "  , Desc: "",  DeployDate: {Month: "March" , year: "2020" }},
        {SiteName : "Civil Hospital Shikarpur, Sindh, Pakistan "  , Desc: "",  DeployDate: {Month: "March" , year: "2020" }},
        {SiteName : "Children Hospital Karachi & Omair Sana Foundation"  , Desc: "",  DeployDate: {Month: "September" , year: "2020" }},
        {SiteName : "PMCH Nawabshah Civil Hospital"  , Desc: "",  DeployDate: {Month: "September" , year: "2020" }},
        {SiteName : "Shahdadpur Institute of Medical Sciences (SIMS)"  , Desc: "",  DeployDate: {Month: "February" , year: "2021" }},
        {SiteName : "Fatimid Foundation, Karachi, Pakistan"  , Desc: "",  DeployDate: {Month: "March" , year: "2021" }},
        {SiteName : "Jinnah Post Graduate Medical Center, Karachi, Pakistan"  , Desc: "",  DeployDate: {Month: "April" , year: "2020" }},
        {SiteName : "Civil Hospital Ghotki, Sindh, Pakistan "  , Desc: "",  DeployDate: {Month: "September" , year: "2021" }},
        {SiteName : 'HBB Naushahro Feroze', Desc: "", DeployDate: {Month: 'March', year: 2022 }},
        {SiteName :'RBC Skardu, Gilgit Baltistan, Pakistan ', Desc: "",  DeployDate: {Month: 'September' , year:2022  }},
        {SiteName :'GDA Hospital Pishukan Avenue, Gawadar, Pakistan', Desc: "",  DeployDate: {Month: 'October' , year:2022  }},
        {SiteName : 'Central Blood Bank, Dammam', Desc: "", DeployDate: {Month: 'March', year: 2023 }},
        {SiteName : 'Liaquat National Hospital', Desc: "", DeployDate: {Month: 'January', year: 2024 }},
        {SiteName : 'Al Hammadi Al Swaidi', Desc: "", DeployDate: {Month: 'Febraury', year: 2024 }},
        {SiteName : 'Al Hammadi Al Nuzha', Desc: "", DeployDate: {Month: 'Febraury', year: 2024 }},
    ]

    return (
        <section className="history-area ptb-100 bg-fafafb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        Our History 
                    </span>
                    <h2>Panacea Deployments</h2>
                </div>

                <ol className="timeline history-timeline">
                    {
                        sites.sort((a,b) => a.DeployDate.year - b.DeployDate.year).map(site => ( 
                            <li className="timeline-block">
                            <div className="timeline-date">
                                <span>{site.DeployDate.year}</span>
                                {site.DeployDate.Month}
                            </div>
    
                            <div className="timeline-icon">
                                <span className="dot-badge"></span>
                            </div>
    
                            <div className="timeline-content">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 col-md-12">
                                        <div className="content">
                                            <p>{site.SiteName}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        ))}

                </ol>

            </div>
        </section>
    )
}

export default PanaceaDeployments