import React from 'react'
import starIcon from '../../assets/images/star-icon.png'

const WFMDeployments = () => {

    const sites = [
        {SiteName : "The Indus Hospital Blood Center, Karachi, Pakistan"  , Desc: "",  DeployDate: {Month: "Jun" , year: "2019" }},
        {SiteName : "RBC Jamshoro LUMS, Jamshoro, Pakistan"  , Desc: "",  DeployDate: {Month: "Jun" , year: "2019" }},
        {SiteName : "RBC Multan, Pakistan"  , Desc: "",  DeployDate: {Month: "Jul" , year: "2019" }},
        {SiteName : "RBC Bahawalpur, Pakistan"  , Desc: "",  DeployDate: {Month: "Aug " , year: "2019" }}
    ]
    return (
        <div className="history-area ptb-100" id="WFM">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        Our History
                    </span>
                    <h2>WFM Deployments</h2>
                </div>
                
                <ol className="timeline history-timeline history-timeline-style-two">
                {
                        sites.sort((a,b) => a.DeployDate.year - b.DeployDate.year).map(site => ( 
                            <li className="timeline-block">
                            <div className="timeline-date">
                                <span>{site.DeployDate.year}</span>
                                {site.DeployDate.Month}
                            </div>
    
                            <div className="timeline-icon">
                                <span className="dot-badge"></span>
                            </div>
    
                            <div className="timeline-content">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 col-md-12">
                                        <div className="content">
                                            <p>{site.SiteName}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        ))}
                </ol>
            </div>
        </div>
    )
}

export default WFMDeployments