import React, { useEffect } from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import PanaceaDeployments from '../components/History/PanaceaDeployments'
import VitaliaDeployments from '../components/History/VitaliaDeployments'
import WFMDeployments from '../components/History/WFMDeployments'
import scrollTo from 'gatsby-plugin-smoothscroll';
import TagsForAll from '../components/Common/TagsForAll'
import { Helmet } from 'react-helmet'

const History = ({location}) => {

    useEffect(() => {
        if(location.hash && typeof window !== undefined){
          scrollTo(location.hash)
          console.log(location)
        }
    }, [location])
    
    return (
        <Layout>
        <Helmet>
            <TagsForAll pageTitle="History" />
            <meta name='robots' content='index, follow' />
            <link rel="canonical" href="https://zaavia.net/history/" />
        </Helmet>
            <Navbar />
            <PageBanner
                pageTitle="History" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="History" 
            />
            <PanaceaDeployments />
            <VitaliaDeployments />
            <WFMDeployments />
            <Footer />
        </Layout>
    );
}

export default History